import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { jwtDecode } from 'jwt-decode';
import axiosInstance from '../api/axiosConfig';
import { getEventTypes } from '../api/eventTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate
import { CheckIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

const CreateEvent = () => {
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState('');
  const [formData, setFormData] = useState({
    nombre: '',
    aprobacion_documentacion: false,
    descripcion: '',
    montoEstimado: '',
    fechaDelEvento: '',
    additional_fields: {},
    firstApprover: ''
  });
  const [additionalFields, setAdditionalFields] = useState([]);
  const [flyer, setFlyer] = useState(null);
  const navigate = useNavigate(); // Usamos useNavigate

  // Obtener el user_id del token JWT
  const token = localStorage.getItem('access'); // o donde almacenes tu token
  const decodedToken = jwtDecode(token);
  const userId = parseInt(decodedToken.user_id, 10);

  const [obligatorio, setObligatorio] = useState(false);
  const [loading, setLoading] = useState(true);
  const [firstApprover, setFirstApprover] = useState([]);

  // Obtener tipos de evento al montar el componente
  useEffect(() => {
    // const fetchEventTypes = async () => {
    //   try {
    //     const data = await getEventTypes();
    //     setEventTypes(data);
    //   } catch (error) {
    //     console.error('Error fetching event types:', error);
    //   }
    // };

    const consultar = async () => {
      try {
        const response = await axiosInstance.get("/v1/event-types/");
        if (response) {
          // alert('cargo todo')
          setLoading(false);
          setEventTypes(response.data);

        } else {
          return "No se ejecuto"
        }
      } catch (error) {
        // return error;
        console.log(error)
      }
    }
    consultar();
    handleFirstApprovers();

    // fetchEventTypes();
  }, []);

  const handleFirstApprovers = async () => {
    try {
      const response = await axiosInstance.get('/v1/events/first-approvers/');
      if (response) {
        console.log(response.data)
        setFirstApprover(response.data)
      } else {
        console.log("")
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Actualizar campos adicionales cuando se selecciona un tipo de evento
  useEffect(() => {
    const selectedType = eventTypes.find(type => type.id === parseInt(selectedEventType));
    setAdditionalFields(selectedType ? selectedType.campos_adicionales : []);
    console.log(`Este a es la respuesta: -> setAdditionalFields: ${additionalFields}`);
  }, [selectedEventType, eventTypes]);

  // CAMBIO EN EL HANDLE PARA ACEPTAR EL PARAMETRO CHECKBOX
  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;

    // Lógica de formateo solo para el campo 'montoEstimado'
    const newValue = name === 'montoEstimado' ? formatNumber(value) : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      // [name]: type === 'checkbox' ? checked : value, // Detecta si es un checkbox
      [name]: type === 'checkbox' ? checked : newValue, // Detecta si es un checkbox
    }));

    // Si el campo que se cambia es el checkbox 'aprobacion_documentacion', actualiza el estado 'obligatorio'
    if (name === 'aprobacion_documentacion') {
      setObligatorio(checked); // Si está marcado, el campo de archivo será requerido
    }

  };

  // Actualizar campos adicionales en formData
  const handleFieldChange = (e, field) => {
    const { value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      additional_fields: {
        ...prevFormData.additional_fields,
        [field.id]: value
      }
    }));
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFlyer(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const queryClient = useQueryClient();

  const getFlyers = () => {
    const form = document.getElementById('form');
    const fileInputs = form.querySelectorAll('input[type="file"]');
    const files = []; // Cambiamos a un array de File para poder enviar al servidor

    fileInputs.forEach(input => {
      if (input.files.length > 0) {
        const descripcion = input.getAttribute('data-descripcion'); // Obtenemos el valor del atributo data-descripcion
        for (let i = 0; i < input.files.length; i++) {
          // files.push(input.files[i]); // Guardamos los archivos en el array
          files.push({
            file: input.files[i],  // Guardamos el archivo
            descripcion: descripcion // Guardamos la descripción asociada
          });
        }
      }
    });

    return files; // Devolvemos el array de archivos
  };

  // FUNCIONES PARA MANEJAR LOS ARCHIVOS CARGADOS DENTRO DE LOS INPUT FILE
  // Función para manejar el evento onChange
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0]; // Obtén el primer archivo
  //   if (file) {
  //     // Encuentra el <p> más cercano y actualiza su contenido
  //     const paragraph = event.target.closest('div').querySelector('.archivoName');
  //     if (paragraph) {
  //       // paragraph.textContent = `Archivo seleccionado: ${file.name}`;
  //       paragraph.textContent = `${file.name}`;
  //       // paragraph.innerHTML = `<div><CheckIcon className="w-5 h-5 mr-1" /><p>${file.name}</p></div>`;
  //     }
  //   }
  // };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Obtén el primer archivo
    if (file) {
      // Encuentra el <div> más cercano con la clase 'fileContainer'
      const fileContainer = event.target.closest('.fileContainer');
      if (fileContainer) {
        // Busca el <p> con la clase 'archivoName' dentro del contenedor
        const paragraph = fileContainer.querySelector('.archivoName');
        if (paragraph) {
          // Actualiza el contenido del <p> con el nombre del archivo
          paragraph.innerHTML = `<div><CheckIcon className="w-5 h-5 mr-1" />${file.name}</div>`;
        }
      }
    }
  };

  // VALIDAR LOS CAMPOS DE SEAN REQUIRE
  const validateForm = () => {
    // Obtener el formulario por su id
    const form = document.getElementById('form');

    // Seleccionar todos los inputs que tengan el atributo "required"
    const requiredFields = form.querySelectorAll('input[required], textarea[required], select[required]');

    // Almacenar los nombres o ids de los campos que faltan
    let missingFields = [];

    // Recorrer todos los campos requeridos
    requiredFields.forEach(field => {
      // Si el campo está vacío o es null, lo agregamos a la lista de faltantes
      if (!field.value || field.value.trim() === '') {
        // Usamos el atributo "name" o "id" para identificar el campo en el mensaje de alerta
        const fieldName = field.name || field.id || 'Campo sin nombre';
        missingFields.push(fieldName);
      }
    });

    // Si hay campos faltantes, mostrar una alerta y retornar false
    if (missingFields.length > 0) {
      // alert(`Por favor, completa los siguientes campos: ${missingFields.join(', ')}`);
      Swal.fire({
        icon: 'error',
        title: 'Atención',
        text: `Todos los campos de la tabla son requeridos, campos: ${missingFields.join(', ')}`,
      });
      return false;
    }

    // Si todos los campos están completos, retornar true
    return true;
  };

  // CREAR EL EVENTO
  const createEvent = async (newEvent) => {

    // Validar el formulario antes de continuar
    if (!validateForm()) {
      return null; // Si faltan campos, detener la ejecución
    }

    // Convertir monto_estimado a entero eliminando los puntos
    const montoEstimadoEntero = parseInt(newEvent.montoEstimado.replace(/\./g, ''), 10);

    const filesArray = getFlyers(); // Obtener los archivos como un array
    console.log(filesArray); // Para ver los archivos seleccionados y sus descripciones

    const data = {
      tipo_evento: selectedEventType,
      nombre: newEvent.nombre,
      descripcion: newEvent.descripcion,
      // monto_estimado: newEvent.montoEstimado,
      monto_estimado: montoEstimadoEntero, // Usar el monto estimado como un entero
      fecha_evento: newEvent.fechaDelEvento,
      user: userId,
      aprobacion_documentacion: newEvent.aprobacion_documentacion,
      additional_fields: newEvent.additional_fields,
      firstApprover: newEvent.firstApprover
    };

    try {
      // Crear el evento primero
      const eventResponse = await axiosInstance.post('v1/events/create/', data);

      // Obtener el ID del evento recién creado
      const eventId = eventResponse.data.evento.id;
      console.log(eventId);

      // Si hay archivos para subir, los procesamos
      if (filesArray.length > 0) {
        for (let i = 0; i < filesArray.length; i++) {
          const flyerObj = filesArray[i];
          const flyer = flyerObj.file;
          const descripcion = flyerObj.descripcion; // Obtenemos la descripción asociada al archivo

          // Crear un FormData para cada archivo
          let formData = new FormData();
          formData.append('flyer', flyer); // Añadir el archivo
          formData.append('descripcion', descripcion); // Añadir la descripción
          formData.append('event_id', eventId); // Añadir el ID del evento

          // Hacer la solicitud POST para subir el flyer
          const uploadResponse = await axiosInstance.post('v1/events/upload_flyer/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          console.log(`Flyer ${i + 1} subido exitosamente:`, uploadResponse.data);
        }
      }

      // Devolvemos los datos del evento creado
      return eventResponse.data;
    } catch (error) {
      console.error('Error al crear el evento o subir archivos:', error);
      throw error; // Para propagar el error si es necesario
    }
  };

  // AGREGAR PUNTOS DE MILES
  const formatNumber = (value) => {
    // Elimina caracteres no numéricos
    const numericValue = value.replace(/\D/g, '');
    // Formatea el número con puntos de miles
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const mutation = useMutation({
    mutationFn: createEvent,
    onSuccess: (data) => {
      // Solo si `createEvent` devuelve datos válidos (evento creado)
      if (data) {
        queryClient.invalidateQueries('events');
        Swal.fire({
          icon: 'success',
          title: 'Evento creado',
          text: 'El evento ha sido creado exitosamente',
        }).then(() => {
          navigate(`/evento/${data.evento.id}`); // Redirigimos al evento creado
        });
      }
    },
    onError: (error) => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Ocurrió un error al crear el evento: ${error.message}`,
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(formData);
  };

  return (
    <div className="flex items-center justify-center h-full p-6 bg-gray-100">

      {/* <div style={{ background: 'transparent', position: 'fixed', width: '50%', height: '50%', display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ background: '#eef1f6', paddingTop: 40, paddingBottom: 40, position: 'absolute', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 8, paddingInline: 40, borderWidth: 2, borderStyle: 'solid', borderColor: '#94a3b8', gap: 10 }}>
          <ArrowPathIcon className="w-7 h-7 mr-1" style={{ color: '#94a3b8' }} />
          <p style={{ color: '#94a3b8' }}>Cargando...</p>
        </div>
      </div> */}

      <div className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
        <h2 className="mb-6 text-3xl font-semibold text-gray-800">Agregar evento</h2>
        <form id='form' className="space-y-6" onSubmit={handleSubmit} noValidate>
          <input type="hidden" name="user_id" value={userId} />
          <div>
            <label className="block mb-2 font-medium text-gray-600">Tipo de evento</label>
            <select
              name="tipoEvento"
              value={selectedEventType}
              onChange={(e) => setSelectedEventType(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            >
              <option value="">Seleccione tipo de evento</option>
              {Array.isArray(eventTypes) && eventTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.nombre}
                </option>
              ))}
            </select>
          </div>
          {selectedEventType && (
            <>
              <div>
                <label className="block mb-2 font-medium text-gray-600">Nombre</label>
                <input
                  type="text"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Ingrese nombre"
                  required
                />
              </div>

              <div>
                <label className="block mb-2 font-medium text-gray-600">Aprobador Principal</label>
                <select
                  name="firstApprover"
                  value={formData.firstApprover}  // Asegúrate de que el estado `formData` tenga el campo `firstApprover`
                  onChange={handleInputChange}    // Reutilizando la misma función `handleInputChange`
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                >
                  <option value="">Seleccione Aprobador</option>
                  {Array.isArray(firstApprover) && firstApprover.map((approver) => (
                    <option key={approver.id} value={approver.id}>
                      {approver.first_name}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ display: "flex", gap: 8, alignItems: 'baseline' }}>
                <input
                  type="checkbox"
                  name="aprobacion_documentacion"
                  checked={!!formData.aprobacion_documentacion} // Convertir a booleano si es necesario
                  onChange={handleInputChange}
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <label class="block mb-2 font-medium text-gray-600">Con Aprobación Documentaria</label>
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-600">Descripción</label>
                <textarea
                  name="descripcion"
                  value={formData.descripcion}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Ingrese una descripción"
                  required
                />
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-600">Monto estimado</label>
                <input
                  type="text"
                  name="montoEstimado"
                  value={formData.montoEstimado}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Ingrese monto estimado"
                  required
                />
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-600">Fecha del evento</label>
                <input
                  type="date"
                  name="fechaDelEvento"
                  value={formData.fechaDelEvento}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              {additionalFields.map((campo) => (
                <div key={campo.campo.id}>
                  <label className="block mb-2 font-medium text-gray-600">{campo.campo.descripcion}</label>
                  {campo.campo.tipo_campo === 'text' && (
                    <input
                      type="text"
                      name={campo.campo.id} // Usamos el id como nombre para simplificar el envío
                      onChange={(e) => handleFieldChange(e, campo.campo)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder={`Ingrese ${campo.campo.titulo_singular}`}
                      required
                    />
                  )}
                  {campo.campo.tipo_campo === 'date' && (
                    <input
                      type="date"
                      name={campo.campo.id}
                      onChange={(e) => handleFieldChange(e, campo.campo)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  )}
                  {campo.campo.tipo_campo === 'time' && (
                    <input
                      type="time"
                      name={campo.campo.id}
                      onChange={(e) => handleFieldChange(e, campo.campo)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  )}
                  {campo.campo.tipo_campo === 'amount' && (
                    <input
                      type="number"
                      name={campo.campo.id}
                      onChange={(e) => handleFieldChange(e, campo.campo)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder={`Ingrese ${campo.campo.nombre}`}
                      required
                    />
                  )}
                  {campo.campo.tipo_campo === 'file' && (
                    // <div className='fileContainer'>
                    //   <section
                    //     {...getRootProps()}
                    //     className={`w-full px-3 py-8 bg-gray-50 border-2 border-dashed border-gray-300 rounded-md shadow-sm focus:outline-none ${isDragActive ? 'border-blue-500' : ''
                    //       }`}
                    //   >
                    //     <input {...getInputProps()} name={"file_" + campo.campo.id} data-descripcion={campo.campo.descripcion} required={obligatorio} style={{ display: 'block', height: 0, opacity: 0 }} onChange={handleFileChange} />
                    //     <input {...getInputProps()} name={"file_" + campo.campo.id} data-descripcion={campo.campo.descripcion} required={obligatorio} style={{ display: 'block', }} />
                    //     {isDragActive ? (
                    //       <p className="text-center text-gray-600">Suelta el archivo aquí...</p>
                    //     ) : (
                    //       <p className="text-center text-gray-600">
                    //         Arrastra y suelta un archivo aquí, o haz clic para seleccionar uno
                    //       </p>
                    //     )}
                    //   </section>
                    //   <p className="archivoName text-gray-600 mt-2 font-semibold">Aún no se ha seleccionado un archivo.</p>
                    // </div>

                    <div className='fileContainer'>
                      <label className='w-full px-3 py-8 bg-gray-50 border-2 border-dashed border-gray-300 rounded-md shadow-sm focus:outline-none' style={{ display: 'block' }} for={"file_" + campo.campo.id}>
                        <p className="text-center text-gray-600">
                          Haz clic para seleccionar un archivo.
                        </p>
                      </label>
                      <input type='file' id={"file_" + campo.campo.id} name={"file_" + campo.campo.id} data-descripcion={campo.campo.descripcion} required={obligatorio} style={{ display: 'block', height: 0, opacity: 0 }} onChange={handleFileChange} />
                      <p className="archivoName text-gray-600 mt-2 font-semibold">Aún no se ha seleccionado un archivo.</p>
                    </div>

                  )}
                </div>
              ))}
            </>
          )}
          <button
            type="submit"
            className="w-full px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            disabled={!selectedEventType}
          >
            Crear y agregar detalles
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateEvent;
