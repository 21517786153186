import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileDropdown from './ProfileDropdown';
import { BellIcon } from '@heroicons/react/24/outline';
import useNotifications from '../hooks/useNotifications';
import Modal from 'react-modal';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import axiosInstance from '../api/axiosConfig';
import useUserProfile from '../hooks/useUserProfile';


const Header = () => {
  const { data: notifications, refetch } = useNotifications();
  const { data: userProfile } = useUserProfile();
  const unreadCount = notifications ? notifications.filter(notification => !notification.read).length : 0;

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const navigate = useNavigate();

  const openNotifications = () => {
    setIsNotificationsOpen(true);
  };

  const closeNotifications = () => {
    setIsNotificationsOpen(false);
  };

  const isApprover = userProfile?.es_aprobador;
  const isApproverDocumentacion = userProfile?.es_aprobador_documentacion;

  const handleNotificationClick = async (notification) => {
    try {
      await axiosInstance.put(`/v1/notifications/${notification.id}/mark-as-read/`);
      refetch();
      if(isApprover){
        navigate(`/aprobar-evento/${notification.evento}`);
      } else if(isApproverDocumentacion){
        navigate(`/aprobar-documento/${notification.evento}`);
      } else {
        navigate(`/evento/${notification.evento}`);
      }      
      closeNotifications();
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <header className="flex items-center justify-between p-4 bg-white shadow-md">
      <h1 className="text-xl font-semibold"></h1>
      <div className="flex items-center space-x-4">
        <button className="relative" onClick={openNotifications}>
          <BellIcon className="w-6 h-6 text-gray-600" />
          {unreadCount > 0 && (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-600 rounded-full">
              {unreadCount}
            </span>
          )}
        </button>
        <ProfileDropdown />
      </div>
      <Modal
        isOpen={isNotificationsOpen}
        onRequestClose={closeNotifications}
        contentLabel="Notificaciones"
        className="modal"
        overlayClassName="overlay"
      >
        <h2 className="text-xl font-bold">Notificaciones</h2>
        <div className="mt-4 space-y-2">
          {notifications && notifications.length === 0 ? (
            <p>No hay notificaciones.</p>
          ) : (
            notifications && notifications
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Orden descendente por fecha
              .map((notification, index) => (
                <div 
                  key={index} 
                  className={`p-4 bg-white rounded-lg shadow-md ${!notification.read ? 'bg-blue-100' : ''}`} 
                  onClick={() => handleNotificationClick(notification)}
                >
                  <p>{notification.mensaje}</p>
                  <p className="text-sm text-gray-500">
                    {formatDistanceToNow(new Date(notification.created_at), { addSuffix: true, locale: es })}
                  </p>
                </div>
              ))
          )}
        </div>
        <button onClick={closeNotifications} className="px-4 py-2 mt-4 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          Cerrar
        </button>
      </Modal>
    </header>
  );
};

export default Header;
