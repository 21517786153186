import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../api/axiosConfig';

const fetchUserProfile = async () => {
  const { data } = await axiosInstance.get('v1/auth/me/');

  // Verificamos si is_approver_one es true
  if (data.is_approver_one) {
    // Si es true, establecemos es_aprobador a true
    data.es_aprobador = true;
    data.orden_aprobador = 0;
  }

  // console.log(data)
  // console.log('-----------')
  return data;
};

const useUserProfile = () => {
  return useQuery({
    queryKey: 'userProfile',
    queryFn: fetchUserProfile,
    keepPreviousData: true,
  });
};

export default useUserProfile;